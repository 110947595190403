import { Component, OnInit } from '@angular/core';
import { UsersService } from '../_services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import {UpdateService} from '../_services/update.service'

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.css']
})
export class GoogleLoginComponent implements OnInit {
 
  modalDisplay='none';
  loadingFlag=0;
  invalidLoginShow=0
  first:String
  last:String
  emailId:String
  role:String
  accessDetails={"First Name":"","Last Name":"","Email ID":"","Role":""}



  constructor(private userService: UsersService, private router:Router, private updateService: UpdateService) { 
    this.updateService.invalidLoginFlag.subscribe(
      status=>{
        console.log("inside invalid flag in login component")
        console.log(status)
        
        this.showInvalidLogin(status)
        console.log(this.invalidLoginShow)
      }
    );
  }

  ngOnInit() {
    this.loadingFlag=0;
  }

  signIn(){
    this.invalidLoginShow=0
    this.loadingFlag=1;
    this.userService.signIn();
    
  }

  register(){
    this.router.navigate(["/register"]);
  }

  firstName($event){
    this.first=$event
  }

  accessRequest($event,type){
    console.log("check in accessRequest",$event,type)
    if(type=='first'){
      this.accessDetails["First Name"]=$event
    }
    if(type=='last'){
      this.accessDetails["Last Name"]=$event
    }
    if(type=='email'){
      this.accessDetails["Email ID"]=$event
    }
    if(type=='role'){
      this.accessDetails["Role"]=$event
    }


  }
  Submit(){
    console.log("Access Details",this.accessDetails)
    this.onCloseHandled()
  }

  openModal(){
    this.modalDisplay='block'
  }

  onCloseHandled(){
    this.modalDisplay='none'
  }

  showInvalidLogin(status){
    this.loadingFlag=0;
    this.invalidLoginShow=status
  }


}
