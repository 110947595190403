import { Component, OnInit } from '@angular/core';
import { RestService } from '../_services/rest.service';
import { ElasticsearchService } from '../_services/elasticsearch.service';
import {TranslateService} from '@ngx-translate/core'
import {Options,ChangeContext, PointerType} from 'ng5-slider';
import { UpdateService } from '../_services/update.service';

export interface RangeSliderModel {
  minValue: number;
  maxValue: number;
  options: Options;
}

@Component({
  selector: 'app-image-elastic-search',
  templateUrl: './image-elastic-search.component.html',
  styleUrls: ['./image-elastic-search.component.css']
})
export class ImageElasticSearchComponent implements OnInit {

  private queryText = '';
  originalResponse:any;
  imageSearchResults: any;
  language='en'


  constructor(
    private restService: RestService,
    private translate:TranslateService,
    private es: ElasticsearchService,
    private updateService:UpdateService) { 
    this.updateService.originalImageResponse$.subscribe(
      data=>{
        this.originalResponse=data;
      }
    )
    this.updateService.currentLanguage$.subscribe(
      data=>{
          this.language=data;
          translate.use(this.language);
      }
  )
      //Code for language selection
      this.language=this.translate.currentLang
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang(sessionStorage.getItem("language"));
      const browserLang = translate.getBrowserLang();
  }

  ngOnInit() {
    this.language=sessionStorage.getItem("language")
  }

    //Function for performing elastic search on image page
    search($event,type) {
      if (type=='i'){
        this.queryText = $event.target.value;
      }else{
        this.queryText=$event;
      }
        var obj={
          "query":this.queryText,
          "request_id_list":this.originalResponse.request_id_list,
          "overall_response":this.originalResponse.overall_response,
        };
        
        this.es.imageTextSearch(obj).subscribe(
            Response => {
              this.imageSearchResults={}
              this.imageSearchResults=JSON.parse(JSON.stringify(Response.body));
              this.updateService.updateImageResponse(this.imageSearchResults);
            },
            error =>{
              var matchImageErr = true;
              this.updateService.updateErrflags(matchImageErr);
          });
    }

}
