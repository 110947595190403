import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';
import { ExploreComponent } from './explore/explore.component';
import { TLCComponent } from './tlc/tlc.component';
import {GoogleLoginComponent} from './google-login/google-login.component';
import {ReferencePlateComponent} from './reference-plate/reference-plate.component'
import {InstructionsComponent} from './instructions/instructions.component'
import { UploadImageComponent } from './upload-image/upload-image.component';
import { AdminConsoleComponent } from './admin-console/admin-console.component';


const appRoutes: Routes = [
    { path: '', component: GoogleLoginComponent, },
    { path: 'login', component: GoogleLoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'explore', component: ExploreComponent,canActivate: [AuthGuard]},
    { path: 'tlc', component: TLCComponent,canActivate: [AuthGuard]},
    {path: 'home',component:HomeComponent,canActivate: [AuthGuard]},
    {path: 'ref',component:ReferencePlateComponent,canActivate: [AuthGuard]},
    {path:'instruction', component:InstructionsComponent, canActivate: [AuthGuard]},
    {path:'admin-console',component:AdminConsoleComponent,canActivate: [AuthGuard]},

    // otherwise redirect to home
    { path: '**', component: GoogleLoginComponent }
];

export const routing = RouterModule.forRoot(appRoutes,{useHash:true});