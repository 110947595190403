import {Injectable,NgZone,Injector} from "@angular/core";
import * as _ from "lodash";
import {GoogleAuthService} from "ng-gapi/lib/GoogleAuthService"
import GoogleUser = gapi.auth2.GoogleUser;
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import { RestService } from '../_services/rest.service';
import {UpdateService} from '../_services/update.service'




@Injectable()

export class UsersService {

  public authentication_response:Object;
  public auth_code: string="abcd";

  Auth_backend="https://backend-dot-solvay-ri-tlc-dev.appspot.com";
  Auth_google_backend="https://europe-west1-solvay-ri-tlc-dev.cloudfunctions.net";
  Auth_URL= environment.api_url;
  
 
  //Variable to control where the login is happening from
  loginFromModal=0

  

//Variable to read expires_at
  expiryTime=new Subject <string>();
  expiryTime$=this.expiryTime.asObservable();

  constructor(
    private injector: Injector,
    private  httpClient:  HttpClient,
    private googleAuthService: GoogleAuthService,
    private router: Router,
    private restService: RestService,
    private updateService: UpdateService) { }

  

    public signIn() {

      this.googleAuthService.getAuth().subscribe((auth) => {
          auth.signIn().then(res => this.signInSuccessHandler(res), err => this.signInErrorHandler(err));
      });
    }

    private signInSuccessHandler(res: GoogleUser){
      sessionStorage.setItem("id_token",res.getAuthResponse().id_token)
      var expires_at=res.getAuthResponse().expires_at.toString()
      this.restService.getIDtoken();
      sessionStorage.setItem("timer",expires_at)
      sessionStorage.setItem("language","en");

      this.validateToken().subscribe(
        Response=>{
          console.log('ValidateToken response');
          
             var result=JSON.parse(JSON.stringify(Response.body));
            var valid=result.validity;
            console.log(valid)
            if (valid=="valid"){
            sessionStorage.setItem('currentUser',JSON.stringify(result));
            this.navigate()
            }else{
              this.invalidTokenHandler()
            }
        });
    }
    
   
    public validateToken(){
      var id_token=sessionStorage.getItem('id_token')
      var validation_object={
        "id_token": id_token
      }
      console.log(id_token);
      
      return this.httpClient.post(`${this.Auth_URL}/validate/`,validation_object,{
        headers:new HttpHeaders({"Content-Type":"application/json"}),
        reportProgress:true,
         observe:'response',
      });

    
      
    }
    

    navigate(){
      
    if(this.loginFromModal==0){
    sessionStorage.setItem("select language","yes")
    const ngZone = this.injector.get(NgZone);
    ngZone.run(() => {
      this.router.navigate(['/home'], { skipLocationChange: true });
    });
    }
    }
    
    private signInErrorHandler(err) {
      console.warn(err);
    }

    signOut(){
      this.loginFromModal=0;
      this.router.navigate(["/login"]);
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("timer");
      sessionStorage.removeItem("language");
      sessionStorage.removeItem("select language")
    }

    LoginModalFlag(){
    this.loginFromModal=1
    }

  
    updateTimer(){
      this.expiryTime.next(sessionStorage.getItem("timer"))
     }

    invalidTokenHandler(){
      console.log("inside invalid tag in user service")
      this.updateService.updateInvalidLoginFlag(1);
    }
  
}
