import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RestService } from '../_services/rest.service';

import { AlertService } from '../_services';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.css']})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = 'none'

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private restService: RestService) { }

    ngOnInit() {

        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            designation: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = 'block';
        var obj={
            "firstName":this.registerForm.get("firstName").value,
            "lastName":this.registerForm.get("lastName").value,
            "email":this.registerForm.get("email").value,
            "designation":this.registerForm.get("designation").value
        }
        this.restService.requestAccess(obj).subscribe(Response =>{
            console.log("Response",Response)
            var temp=JSON.parse(JSON.stringify(Response.body));
          })


        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        // console.log("First Name", this.registerForm.get("firstName").value)
        this.loading = true;

    
    }
}