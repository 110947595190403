/*import { NgModule} from '@angular/core';*/
import { Component} from '@angular/core';
import { ImageCroppedEvent } from '../image-cropper/image-cropper.component';
import { RestService } from '../_services/rest.service';
import { NgxGalleryOptions,NgxGalleryAnimation} from 'ngx-gallery';
import {TranslateService} from '@ngx-translate/core'
import {Options,ChangeContext} from 'ng5-slider';
import {UpdateService} from '../_services/update.service'


export interface YearSliderModel {
  minValue: number;
  maxValue: number;
  options: Options;
}

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent {

  imageChangedEvent: any = '';
  actualEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;
  uploadPlaceholderVisible=true;
  sliderVisible=false;
  loadingVisible=false;
  mp=""
  ra=""
  showMultiPlateError=0;
  showNoPlateError=0;
  showImageHist=0;
  language='en'


  searchedImages:any[]=[];
  galleryOptions: NgxGalleryOptions[];
  processedImage
  processedImageArray=[]

  base64textString=""
  resultImage=""
  showNoSpotImage=0

  Imagestr=""
  Imagestr_cfms=""


  //Variable to count no of times image has been uploaded
  uploadCounter=0

  //Radio button label variable
  labelPosition 

  //Modal for asking user selection of search type
  modalDisplay='none'

 //Variables for year filter
startYear=1990
currentDate=new Date()
endYear=this.currentDate.getFullYear()
yearModalDisplay='none'
selectedStartYear=this.startYear
selectedEndYear=this.endYear
newKeywordSearchedFlag

//Variable for slider options

 yearSlider: YearSliderModel={
   minValue: this.startYear,
     maxValue: this.startYear+2,
 
     options: {
       floor: this.startYear,
       ceil: this.endYear,
       step: 1,
       noSwitching: true,
       showTicks: false,
       draggableRange:true,
       pushRange:true,
       minRange: 1,
       maxRange: 2
       
   }
 }

constructor(private restService: RestService,private translate:TranslateService,private updateService:UpdateService){

  //showing year filter modal

  this.updateService.displayYearFilter$.subscribe(
    data=>{
      if (data==1){
        this.yearModalDisplay='block'
      }else{
        this.yearSlider={
          minValue: this.startYear,
            maxValue: this.startYear+2,
        
            options: {
              floor: this.startYear,
              ceil: this.endYear,
              step: 1,
              noSwitching: true,
              showTicks: false,
              draggableRange:true,
              pushRange:true,
              minRange: 1,
              maxRange: 2
              
          }
        }
        this.yearModalDisplay='block'

      }
    }
  )

  //Storing processed image
  this.updateService.processedImage$.subscribe(
    data=>{
      this.processedImage=data;
    }
  )

  this.updateService.searchedImages$.subscribe(
    data=>{
       this.searchedImages=data;
       if(this.searchedImages.length>=1){
         this.showImageHist=1
       }
       else{
         this.showImageHist=0
       }
       }
   );

  this.updateService.uploadPlaceholder$.subscribe(
    data=>{
      this.uploadPlaceholderVisible=data;
    }
  );
  this.updateService.uploadLoadingVisible$.subscribe(
    data=>{
      this.showNoPlateError=0;
      this.showMultiPlateError=0;
      this.loadingVisible=data;
    }
  );
  this.updateService.selectedMP$.subscribe(
    data=>{
      this.mp=data
    }
  );
  this.updateService.selectedRA$.subscribe(
    data=>{
      this.ra=data
    }
  );
  this.updateService.multiPlateDetected$.subscribe(
    data=>{
      this.loadingVisible=false;
      this.showMultiPlateError=data;
    }
  );
  this.updateService.noPlateDetected.subscribe(
    data=>{
      this.loadingVisible=false;
      this.showNoPlateError=data;
    }
  );

  //updating CFMS selected value
  this.updateService.CFMSselected$.subscribe(
    data=>{
      this.showNoSpotImage=data
      if (this.showNoSpotImage==1){
        this.resultImage="data:image/png;base64,"+this.Imagestr_cfms
        }else{
          this.resultImage="data:image/png;base64,"+this.Imagestr
        }
    }
  )
  this.updateService.currentLanguage$.subscribe(
    data=>{
        this.language=data;
        translate.use(this.language);
    }
)
//Code for language selection
this.language=this.translate.currentLang
translate.addLangs(['en', 'fr']);
translate.setDefaultLang(sessionStorage.getItem("language"));
const browserLang = translate.getBrowserLang();
translate.use(this.language);
}

ngOnInit(){
  this.language=sessionStorage.getItem("language")
  this.galleryOptions = [
    {
        width: '300px',
        height: '300px', 
       
        thumbnailsColumns: 3,
       imageAnimation: NgxGalleryAnimation.Slide,
       thumbnailsPercent: 90,
       imagePercent:100,
       imageSize:'4%',
       thumbnailsMargin: 25,
       previewCloseOnClick:true,
       imageDescription:true,
       previewZoom:true,
       imageSwipe:false,
       image:false
    }
  ]
}
     
//Alternate function for removing mouse hover bug
handleFilechange(event){
  
  this.uploadCounter+=1

  var files = event.target.files;
  var file = files[0];

  if (event && event.target && event.target.files && event.target.files.length > 0) {
      var reader = new FileReader();

      reader.onload =this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);

      if (this.labelPosition=="Fresh"){
        this.processedImageArray=[]
        this.updateService.updatesearchedImages(this.processedImageArray)
      }else if (this.labelPosition=="Filter"){
        this.processedImageArray.push(this.processedImage)
        this.updateService.updatesearchedImages(this.processedImageArray)


      }
      this.actualEvent=event
      this.loadingVisible=true;
      var imageSelected=1
      this.updateService.checkImageSelected(imageSelected)
  }else{
    var imageSelected=0
  this.updateService.checkImageSelected(imageSelected)
  }
  this.imageChangedEvent = this.actualEvent;
}


//function for conversion to base64 string
_handleReaderLoaded(readerEvt) {
  var binaryString = readerEvt.target.result;
         this.base64textString= btoa(binaryString);
         this.updateService.updateInputImage(this.base64textString)
          var obj = {
            "Image" : "data:image/png;base64,"+this.base64textString,
            "MP":this.mp,
            "RA":this.ra
          };
    this.restService.getImage(obj).subscribe(
      data=>{
        var temp=JSON.parse(JSON.stringify(data.body));
        
        if (temp=="Multiplates detected - Please upload single plate image."){
          this.updateService.showMultiplePlateWarning(1);
      } else if (temp=="Sorry! No plate detected"){
          this.updateService.showNoPlateWarning(1);
      }else{
        
        this.resultImage="data:image/png;base64,"+temp.Imagestr
        this.Imagestr=temp.Imagestr
        this.Imagestr_cfms=temp.Imagestr_cfms
        
        var spot_no=temp.Spot_count //for storing spot count to update in cropper
        this.updateService.updateSpotList(spot_no)
        
        var spot_db=temp.spot_db //for storing spot db to update in cropper
        this.updateService.updateSpotDB(spot_db)

        
        //to update the upload image history
        var temp_image_obj={
          "big":this.resultImage,
          "medium":this.resultImage,
          "small":this.resultImage,
          "description": this.mp+"#"+this.ra
      }
      this.updateService.captureProcessedImage(temp_image_obj)
      }
      }
    );
         
 }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.cropperReady = true;
    this.loadingVisible=false
    //changing slider initial value
    this.updateService.changeSliderInitialValues()

  }
  loadImageFailed () {
    this.loadingVisible=false

  }

   //function for handling user selection modal
   openModal(){
    this.labelPosition=null
    this.modalDisplay='block';
  }

  onCloseHandled(){
   
    this.modalDisplay='none'; 
  }
//Function to trigger file change event for select tlc plate
  clickInputTag(){
    var input=document.getElementById("files")
    input.click()
  }

  //Handling on click event of select tlc button
  onImageSelectClick(){
    if (this.uploadCounter==0){
      this.clickInputTag()
    }else{
      this.openModal()
    }
  }

  //Handling radio button click
  onSelectSearchTypeRadio(event){
    this.labelPosition=event.value
    this.updateService.updateImageSelectType(this.labelPosition)
    this.onCloseHandled()
    this.clickInputTag()
      }

  //Functions related to year modal
  onCloseYearModal(){
    this.yearModalDisplay='none'
  }

  onUserChangeYear(event:ChangeContext){
    this.selectedStartYear=event.value
    this.updateService.imageYearFilterStartYear(this.selectedStartYear)
    this.selectedEndYear=event.highValue
    this.updateService.imageYearFilterEndYear(this.selectedEndYear)
  }

  applyYearFilter(){
    this.updateService.applyImageYearFilter()
    this.yearModalDisplay='none'
  }
 
}

