import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { RestService } from '../_services/rest.service';
import { UpdateService } from '../_services/update.service';
import { environment } from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable';

export interface UserRegitrationTable{
  position:number;
  name: string;
  role:string;
  email: string;
  highlighted?: boolean;
  hovered?: boolean;
}


   

@Component({
  selector: 'app-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.css']
})
export class AdminConsoleComponent implements OnInit {

  fileName:String;
  form:FormGroup
  loading:boolean=false;
  base64textString=""
  language="en"
  characters: Observable<any[]>;
  displayedColumns:any[]
  arrayFlag=true;
  uploaded=false;
  uploadError=false;
  uploadLoading=false;
  noFile=true;

  //Variables for data Validation
  bucket_url=environment.cloud
  validateModal='none'
  validationLoading=false;
  validateError=false;
  validated='none'

  //variabes for error handling of response
  
  img_name_error=[]
  doc_ext_error=[]
  doc_name_error=[]
  fold_name_error=[]
  uploadErrorModal='none'
  correctFolders=[]
  incorrectFolders=[]
  emptyDrive=true;

  
  ELEMENT_DATA = []

  @ViewChild('fileInput', {static: false}) fileInput:ElementRef

  constructor(
    private fb:FormBuilder,
    private restService:RestService,
    private translate:TranslateService,
    private updateService:UpdateService) {
    this.createForm();

    {
      this.updateService.currentLanguage$.subscribe(
          data=>{
              this.language=data;
              translate.use(this.language);
          }
      )
    
  //Code for language selection
      this.language=this.translate.currentLang
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang(sessionStorage.getItem("language"));
      const browserLang = translate.getBrowserLang();
      translate.use(this.language);
  }
   }

   createForm(){
     this.form=this.fb.group({
       file:['',Validators.required],
       files: null
     })
   }

  urls = [];
  onSelectFile(event) {
    

   
    let reader = new FileReader();
    if (event.target.files && event.target.files.length>0) {
         let file = event.target.files[0]
         reader.readAsDataURL(file);
         this.fileName=file.name
         this.restService.updateInputFile(file).subscribe(Response =>{
          console.log("The API has been hit",Response)
       //  reader.onload =this._handleReaderLoaded.bind(this);
       })
  }
}

//  _handleReaderLoaded(readerEvt) {
//   this.noFile=false;
//   this.uploadLoading=true;
//   var binaryString = readerEvt.target.result;
//          this.base64textString= btoa(binaryString);
//          console.log("File converted to base 64",this.base64textString)
//          var uploadFile={
//            "base64File":this.base64textString,
//            "fileName":this.fileName
//          }       
//          this.restService.updateInputFile(uploadFile).subscribe(Response =>{
//           console.log(Response)
//           var temp=JSON.parse(JSON.stringify(Response.body));
//           console.log(temp.img_path_error)
//           this.img_name_error=temp.img_name_error
//           this.doc_ext_error=temp.doc_ext_errors
//           this.img_path_error=temp.img_path_error
//           console.log(this.img_path_error)
//           this.fold_name_error=temp.fold_name_errors

//           if(this.img_name_error=this.img_path_error=this.doc_ext_error=this.fold_name_error=null){
//           this.uploadLoading=false;
//           this.uploaded=true;
//           this.uploadError=false;
//           } 
//           if(this.img_name_error!=[] || this.img_path_error!=[] || this.doc_ext_error!=[] || this.fold_name_error!=[])  {
//             this.uploaded=false;
//             this.uploadLoading=false;
//             this.uploadError=true;
//             this.uploadErrorModal='block'
//             console.log(this.img_path_error)
//           }
//         },err=>{this.uploaded=false;
//                 this.uploadLoading=false;
//                 this.uploadError=true;})}
 

openValidateModal(){
  this.validateModal='block'
}

//structureError-depth is not right 
//incorrectExtensions-not a .doc,.docx,.png,.tif
//incorrectDocs-name of the doc does not match the folder name
//incorrectImages-MP RA names are not present in the data base or it contains more than one MP or RA


validateData(){
  this.validateError=false;
  this.emptyDrive=true;
  this.validationLoading=true;
  console.log(this.validationLoading)
  this.restService.validateData().subscribe(Response =>{
    console.log("Data validation API Hit")
    if(Response.body=="No folder uploaded or Uploaded folder name has already been validated in the past. Make sure to upload an un-used folder name"){
      this.emptyDrive=false;
      console.log("empty drive") 
      this.validateModal='none'
      this.validated='block'
      this.validationLoading=false;
      }
    else{
      var temp=JSON.parse(JSON.stringify(Response.body))
      this.img_name_error=temp.incorrectImages
      console.log("img_name",this.img_name_error.length)
      this.doc_ext_error=temp.incorrectExtensions
      this.doc_name_error=temp.incorrectDocs
      this.fold_name_error=temp.structureError
      this.correctFolders=temp.validationSuccess
      console.log(this.correctFolders.length)
      this.incorrectFolders=temp.validationFail
      console.log(Response.body)

      this.validateModal='none'
      this.validationLoading=false;
      this.validated='block'
    }
      
      
    
  
  
    
  },err=>{this.uploadError=true
          this.noFile=false;
          this.validationLoading=false;
          this.validateModal='none'})
}


highlight(element: UserRegitrationTable) {
  element.highlighted = !element.highlighted;
}

//Function to trigger file change event for select tlc plate
clickInputTag(){
  var input=document.getElementById("files")
  input.click()
  this.noFile=true;
  this.uploaded=false;
  this.uploadLoading=false;
  this.uploadError=false;
}

accessReject(row){
this.ELEMENT_DATA.splice(row,1)
}

closeValidatedModal(){
  this.validated='none'
  this.uploaded=false;
  this.uploadLoading=false;
  this.uploadError=false;
  this.noFile=true;
}

  ngOnInit() {
    
  this.language=sessionStorage.getItem("language")
    this.displayedColumns=['Position','Name','Designation','Email']

    setInterval(()=> {
      
    },1000)
  


    this.restService.accessTable().subscribe(Response =>{
      this.arrayFlag=true;
      var temp=JSON.parse(JSON.stringify(Response.body));
      for (var i = 0; i < temp.length; i++){
        this.ELEMENT_DATA.push(temp[i])
      }
    },)
  }

  }
