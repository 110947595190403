import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {


  constructor() { }
//Variable for updating matched image results
match_images=new Subject<Object>();
match_images$=this.match_images.asObservable();

//Variables for error flags
imageMatchErr = new Subject<boolean>();
imageMatchErr$ = this.imageMatchErr.asObservable();

//Variables to show multiple plate error
multiPlateDetected= new Subject<number>();
multiPlateDetected$=this.multiPlateDetected.asObservable();

//Variables to show no plate error
noPlateDetected=new Subject<number>();
noPlateDetected$=this.noPlateDetected.asObservable();

//Variable to update selected jumbo keywords
selectedJumboKeyword=new Subject<string>();
selectedJumboKeyword$=this.selectedJumboKeyword.asObservable();

//Variable for checking is match image is clicked
matchImageClicked=new Subject<number>();
matchImageClicked$=this.matchImageClicked.asObservable();

//Variable to capture jumbo match type
jumboMatchType=new Subject<number>();
jumboMatchType$=this.jumboMatchType.asObservable();

//Variables to change visibility of log in page
loggedIn= new Subject<boolean>();
loggedIn$=this.loggedIn.asObservable();

//Varibales to show loading gif on image-result component
imageShowLoading= new Subject<number>();
imageShowLoading$=this.imageShowLoading.asObservable();

//Variables for upload-component placeholder
uploadPlaceholder= new Subject<boolean>();
uploadPlaceholder$=this.uploadPlaceholder.asObservable();

//Variables to show loading gif on uploading image
uploadLoadingVisible=new Subject<boolean>();
uploadLoadingVisible$=this.uploadLoadingVisible.asObservable();

//Variable to refresh slider values
minValue= new Subject<number>();
minValue$=this.minValue.asObservable();
maxValue=new Subject<number>();
maxValue$=this.maxValue.asObservable();

//Variables for storing value of selected MP and RA
selectedMP= new Subject<string>();
selectedMP$=this.selectedMP.asObservable();
selectedRA= new Subject<string>();
selectedRA$=this.selectedRA.asObservable();
mp=""
ra=""

//Variables for storing value of selected Jumbo keywords
selectedKeyword= new Subject<string>();
selectedKeyword$=this.selectedKeyword.asObservable()

//Variable for controlling visibility of jumbo search bar
jumboVisible=new Subject<boolean>();
jumboVisible$=this.jumboVisible.asObservable();

//Variables for updating jumbo keywords on change of jumbo type
jumboKeyword=new Subject<string[]>();
jumboKeyword$=this.jumboKeyword.asObservable();

//Variables to update minimum and maximum value of slider
Y1=new Subject<number>();
Y1$=this.Y1.asObservable();
Y2=new Subject<number>();
Y2$=this.Y2.asObservable();

//Variables for updating slider after undo button is clicked
undoY1=new Subject<number>();
undoY1$=this.undoY1.asObservable();
undoY2=new Subject<number>();
undoY2$=this.undoY2.asObservable();

//Variable for updating image match response
imageResponse=new Subject<Object>();
imageResponse$=this.imageResponse.asObservable()

//Variables for updating jumbo search results
jumboResults=new Subject<Object>();
jumboResults$=this.jumboResults.asObservable();

//Variables for updating original image response
originalImageResponse=new Subject<Object>();
originalImageResponse$=this.originalImageResponse.asObservable();

//varibale for updating spot number
spotNo=new Subject<string>()
spotNo$=this.spotNo.asObservable();

//Variable for updating color variation
colorVariation= new Subject<number>()
colorVariation$= this.colorVariation.asObservable()

//Varibale for updating intentisty variation
intensityVariation=new Subject<number>()
intensityVariation$=this.intensityVariation.asObservable()

//Variable for updating uploaded image
inputImage=new Subject<string>()
inputImage$=this.inputImage.asObservable()

//Variable for updating cropped image
croppedImage=new Subject<string>()
croppedImage$=this.croppedImage.asObservable()

//Variable for activating secondary search
secondarySearch=new Subject();
secondarySearch$=this.secondarySearch.asObservable();

//Variable for storing searched images
searchedImages=new Subject<any[]>();
searchedImages$=this.searchedImages.asObservable();

//Varibale for updating select tlc image search type
imageSearchType= new Subject<string>()
ImageSearchType$=this.imageSearchType.asObservable()

//Variables for handling multiple image response
imageResponseArray=new Subject<any[]>()
imageResponseArray$=this.imageResponseArray.asObservable()

//Capturing processed image after cropping
processedImage= new Subject<string>()
processedImage$=this.processedImage.asObservable()

//Variable to check if image is selected
imageSelected=new Subject<number>()
imageSelected$=this.imageSelected.asObservable()

//capturing spot image list to remove mouse bug
spotList=new Subject<number>()
spotList$=this.spotList.asObservable()

//capturing spot db to remove mouse bug
spotDB=new Subject<string>()
spotDB$=this.spotDB.asObservable()

//variable to control cant find my spot image view
CFMSselected=new Subject<number>()
CFMSselected$=this.CFMSselected.asObservable()

//language controller
currentLanguage= new Subject<string>()
currentLanguage$= this.currentLanguage.asObservable()

//year filter reset
yearFilter=new Subject<number>()
yearFilter$=this.yearFilter.asObservable()


//year filter for image match
displayYearFilter= new Subject<number>()
displayYearFilter$= this.displayYearFilter.asObservable()

//Image tab start year
imageTabStartYear= new Subject<number>()
imageTabStartYear$= this.imageTabStartYear.asObservable()

//Image tab end year
imageTabEndYear= new Subject<number>()
imageTabEndYear$= this.imageTabEndYear.asObservable()


//Applying image match using year filter in image tab
imageTabYearFilter= new Subject<string>()
imageTabYearFilter$= this.imageTabYearFilter.asObservable()

//Variable for updating response from undo button of multi spot
imageResponseFromMultiSpot=new Subject <Object>()
imageResponseFromMultiSpot$=this.imageResponseFromMultiSpot.asObservable()
//variables for updating visibility of loader in search report page
showSearchLoader=new Subject<number>();
showSearchLoader$=this.showSearchLoader.asObservable();

//variables for updating visibility of error message on search report page
searchReportErr = new Subject<boolean>();
searchReportErr$ = this.searchReportErr.asObservable();

//Variables for controlling the show of error message on invalid login
invalidLoginFlag= new Subject<number>()
invalidLoginFlag$=this.invalidLoginFlag.asObservable()

//Variables for updating signed url
signed_urls=new Subject<Object>();
signed_urls$=this.signed_urls.asObservable();


//function to update selected start year in image tab
imageYearFilterStartYear(value){
  this.imageTabStartYear.next(value)
}

//function to update selected end year in image tab
imageYearFilterEndYear(value){
  this.imageTabEndYear.next(value)
}

//function to apply match image in image tab
applyImageYearFilter(){
  this.imageTabYearFilter.next("apply")
}

//Function to display year filter in image tab
displayYearModal(num){
  this.displayYearFilter.next(1);
}

//Function to check if image is selected or not
checkImageSelected(val){
this.imageSelected.next(val)
}

//Function for handling processed image after cropping
captureProcessedImage(image){
  this.processedImage.next(image)
}

//Function to update multiple plate detected
showMultiplePlateWarning(n){
  this.multiPlateDetected.next(n)
}

//Function to update no plate detected
showNoPlateWarning(n){
  this.noPlateDetected.next(n);
}

//Function to update the error flags

updateErrflags(data){
  this.imageMatchErr.next(data);
}


//Update jumbo matched type
updateJumboMatchtype(type){
  this.jumboMatchType.next(type);
}
//function to update selected jumbo keywords
updateSelectedJumboKeyword(keyword){
  this.selectedJumboKeyword.next(keyword);
}
//Function to show loader in image elastic search
updateLoaderImageElastic(v){
  this.imageShowLoading.next(v);
}
//Function to update original image response
updateImageOriginalResponse(data){
  this.originalImageResponse.next(data);
}
//Function to update jumbo search response
updateJumboTextResponse(response){
  
  this.jumboResults.next(response);
}

//Function to update image response
updateImageResponse(obj){
  
  this.imageResponse.next(obj);
}
//Function to update slider values
updateSliderValues(y1,y2){
  this.Y1.next(y1);
  this.Y2.next(y2);
}
//Function to update jumbo keyword
updateJumboKeyword(array){
this.jumboKeyword.next([])
this.jumboKeyword.next(array);
}
//Function to change jumbo visibility
changeJumboVisibility(value){
  if (value=='Jumbo'){
    this.jumboVisible.next(true)
  }
  else{
    this.jumboVisible.next(false)
  }
}
//Function to change MP value
updateMP(mp){
  this.selectedMP.next(mp)
  this.mp=mp;
}
//Function to change RA value
updateRA(ra){
  this.selectedRA.next(ra)
  this.ra=ra;
}
//Function to update jumbo keyword
updateKeyword(keyword){
  this.selectedKeyword.next(keyword);
}
//Function to toggle values of upload component placeholder
changeUploadPlaceholder(){
  this.uploadPlaceholder.next(false);
}

//Function to show loading gif on uploading image
changeUploadLoading(){
  this.uploadLoadingVisible.next(false);
}

//Function to toggle visibility of slider
changeSliderInitialValues(){
  this.minValue.next(100);
  this.maxValue.next(300);
}

//Function to toggle login visibility
changeLogin(){
 
  this.loggedIn.next(true);
}

//Function for updating spot number
updateSpotNo(spot){
this.spotNo.next(spot)
}
//Function for updating color variation
updateColorVariation(color){
this.colorVariation.next(color)
}
//Function for updating intensity variation
updateIntensityVariation(intensity){
this.intensityVariation.next(intensity)
}
//Function for updating input image 
updateInputImage(image){
this.inputImage.next(image)
}
//Fucntion for updating cropped image
updateCroppedImage(image){
this.croppedImage.next(image)
}

//Function for handling select TLC plate type
updateImageSelectType(type){
  this.imageSearchType.next(type)
}

//Function for updatin image response array
updateImageResponseArray(images){
  this.imageResponseArray.next(images)
}

//function to update searched images
updatesearchedImages(value){
  this.searchedImages.next(value)
  };

//function to update spot db and spot list
updateSpotList(res){
  this.spotList.next(res)
};

updateSpotDB(res){
  this.spotDB.next(res);
}

//updating cant find my spot to show image without spots identified
updateCFMS(value){
  this.CFMSselected.next(value)
}

//language controller
updateLanguage(){
  var lang=sessionStorage.getItem("language")
  this.currentLanguage.next(lang)
}

//Resetting year filter in jumbo search
resetYearFilter(obj){
this.yearFilter.next(obj)
}

//function to update slider after undo multispot is clicked
updateSliderForUndoMultispot(Y1,Y2)
{
  this.undoY1.next(Y1)
  this.undoY2.next(Y2)
}

//function to update image response from undo of multi spot
updateImageResponseFromUndoMultiSpot(obj){
  this.imageResponseFromMultiSpot.next(obj)
}
  //Updating loader on report search page
  updateLoaderForReportSearch(v){
    this.showSearchLoader.next(v);
  }

//Update network error message on report search page
  updateSearchErrFlag(data){
    this.searchReportErr.next(data);
  }

  //function to update the status of invalid login flag
  updateInvalidLoginFlag(status){
    console.log("inside invalid flag in updateservice")
    this.invalidLoginFlag.next(status)
  }


}
