import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { RestService } from '../_services/rest.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';

@Component({
  selector: 'app-reference-plate',
  templateUrl: './reference-plate.component.html',
  styleUrls: ['./reference-plate.component.css']
})



export class ReferencePlateComponent implements OnInit {

  Control_Ref = new FormControl();
  language='en'

  options_Ref: string[]=[];

  filteredOptions_Ref: Observable<string[]>; 
 
  populateFiltered_Ref;

  galleryOptions: NgxGalleryOptions[];

  populateFiltered_ref;

  reference_plates
  selectedFolder=""
  showReferencePlate=0
  showPlaceholder=1
  showLoader=0
  refErr=0

  //Variables for showing image table
  modalImageList
  modalDisplay='none'
//Variables for opening image from image table
  imageModalDisplay='none'
  imageModalGallery: NgxGalleryImage[];
  modalGalleryOptions: NgxGalleryOptions[];

  constructor(
    private restService: RestService,
    private translate:TranslateService,
    private updateService:UpdateService) {
    this.updateService.currentLanguage$.subscribe(
        data=>{
            this.language=data;
            translate.use(this.language);
            if(this.language=='en'){
              this.selectedFolder="Please select a folder"
            }
            if(this.language=='fr'){
              this.selectedFolder="Veuillez sélectionner un dossier"
            }
        }
    )
        //Code for language selection
        this.language=this.translate.currentLang
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang(sessionStorage.getItem("language"));
        const browserLang = translate.getBrowserLang();
   }

  ngOnInit() {
    this.language=sessionStorage.getItem("language")
    if (this.language=="en"){
     this.selectedFolder="Please select a folder"
    }else{
     this.selectedFolder="Veuillez sélectionner un dossier"
    }
  
    
  

    this.modalGalleryOptions=[
      {
        width: '600px',
        height: '600px',
        imageAnimation: NgxGalleryAnimation.Slide,
       imageSize:'4%',
       previewCloseOnClick:true,
       previewZoom:true,
       imageDescription:true,
       thumbnails:false,
       imageArrows:false
    }
     ];

    this.restService.getReference().subscribe(Response =>{
      var temp=JSON.parse(JSON.stringify(Response.body));
      for (var i = 0; i < temp.length; i++){
        this.options_Ref.push(temp[i].ref_plate_folder)
      }

      this.populateFiltered_ref();
      
     });

    this.populateFiltered_ref = function() {
      this.filteredOptions_Ref = this.Control_Ref.valueChanges
       .pipe(
         startWith(''),
         map(value => this._filter(value))
       );
     }




    // this.filteredOptions_Ref = this.Control_Ref.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    //   );
//Galery options for showing reference images
     
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options_Ref.filter(option => option.toLowerCase().includes(filterValue));
  }

  setGalleryOptions(size){
    if (size<5){
      var column= size;
      var w="50%"
    }else{
       column = 5;
       w="80%"
    }
    this.galleryOptions = [
      {
          width: w,
          height: '400px',
          
           thumbnailsColumns: column,
           imageAnimation: NgxGalleryAnimation.Slide,
         thumbnailsPercent: 125,
         imagePercent:125,
         previewCloseOnClick:true,
         imageDescription:true,
         previewZoom:true,
         imageSwipe:false,
         image:false
      }
      
      ];
  }

  RefSelected($event){
    this.showReferencePlate=0
        this.showPlaceholder=0
        this.refErr=0
        this.showLoader=1
    this.selectedFolder=$event.option.value
    var obj={
      "ref_plate_folder": $event.option.value
    }
    this.restService.getReferenceImages(obj).subscribe(
      Response=>{

        var temp_images=JSON.parse(JSON.stringify(Response.body));
        this.reference_plates=temp_images;
        this.setGalleryOptions(this.reference_plates.length)
        this.showLoader=0
        this.showReferencePlate=1;
      },
      error =>{
       
        this.showLoader = 0;
        this.showPlaceholder=0;
        this.refErr = 1;
    }
      
    );
    
  }

  clickHandlerRef(){
    this.Control_Ref.setValue('')
  }

  openImagemodal(images){
    this.modalImageList=images
    this.modalDisplay='block'
  }

  //function to open associated image modal
  onCloseHandled(){
    this.modalDisplay='none'
  }

  openImage(image){
    this.imageModalDisplay='block'
    this.imageModalGallery=[image]
  }
  onCloseImageHandled(){
    this.imageModalDisplay='none'
  }

}
