import { Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { RestService } from '../_services/rest.service';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';

export const mpraSelected={
mp: '',
ra:''


}

@Component({
  selector: 'app-mp-ra-dropdown',
  templateUrl: './mp-ra-dropdown.component.html',
  styleUrls: ['./mp-ra-dropdown.component.css']
})
export class MpRaDropdownComponent implements OnInit  {

  Control_MP = new FormControl();
  Control_RA = new FormControl();
  options_MP: string[]=[];
  options_RA: string[] =[];
  filteredOptions_MP: Observable<string[]>; 
  filteredOptions_RA: Observable<string[]>;
  populateFiltered_MP;
  populateFiltered_RA;
  language=sessionStorage.getItem("language");
  constructor(
    private restService: RestService,
    private translate:TranslateService,
    private updateService:UpdateService){

  this.updateService.currentLanguage$.subscribe(
    data=>{
        this.language=data;
        translate.use(this.language);
    }
)
  //Code for language selection
  this.language=this.translate.currentLang
  translate.addLangs(['en', 'fr']);
  translate.setDefaultLang(sessionStorage.getItem("language"));
  const browserLang = translate.getBrowserLang();
  translate.use(this.language);
  };
  ngOnInit() {
    this.language=sessionStorage.getItem("language")
    var obj={}
    //Getting MP Values
    this.restService.getMP(obj).subscribe(Response =>{

      var temp=JSON.parse(JSON.stringify(Response.body));
      for (var i = 0; i < temp.length; i++){
        this.options_MP.push(temp[i].migration_phase)
      }

      this.populateFiltered_MP();
    });

    //Getting RA values
    this.restService.getRA(obj).subscribe(Response =>{

      var temp=JSON.parse(JSON.stringify(Response.body));
      for (var i = 0; i < temp.length; i++){
        this.options_RA.push(temp[i].revealing_agent)
      }

      this.populateFiltered_RA();
    });
    
   this.populateFiltered_MP = function() {
     this.filteredOptions_MP = this.Control_MP.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter_MP(value))
      );
    }
      
    this.populateFiltered_RA = function(){ this.filteredOptions_RA = this.Control_RA.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter_RA(value))
      );
      }
  }

  private _filter_MP(value: string): string[] {
    const filterValue_MP = value.toLowerCase();

    // return
    console.dir(this.options_MP); 
    let result = this.options_MP.filter(option => option.toLowerCase().includes(filterValue_MP));
    return result;
  }

    private _filter_RA(value: string): string[] {
    const filterValue_RA = value.toLowerCase();

    return this.options_RA.filter(option => option.toLowerCase().includes(filterValue_RA));
  }

  MPSelected(event){
var mp=event.option.value;
this.updateService.updateMP(mp);

  }

  RASelected(event){
    var ra=event.option.value;
    this.updateService.updateRA(ra);
    
      }

  clickHandlerRA(){
    this.Control_RA.setValue('');
    this.updateService.updateRA('')
  }

  clickHandlerMP(){
    this.Control_MP.setValue('');
    this.updateService.updateMP('');
  }

}
