import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule }    from '@angular/http';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { CommonModule } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgxGalleryModule } from 'ngx-gallery';
import { AppComponent } from './app.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { SegmentSliderComponent } from './segment-slider/segment-slider.component';
import { SearchReportComponent } from './search-report/search-report.component';
import '../polyfills';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import {MatNativeDateModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './material-module';
import { MpRaDropdownComponent } from './mp-ra-dropdown/mp-ra-dropdown.component';
import { ImageResultComponent } from './image-result/image-result.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ExploreComponent } from './explore/explore.component';
import { TLCComponent } from './tlc/tlc.component';
import { UsersService } from './_services/users.service';
import { routing } from './app.routing';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { AlertService} from './_services';
import { JumboKeywordComponent } from './jumbo-keyword/jumbo-keyword.component';
import {PagerService} from './_services/pager.service';
import { NavbarComponent } from './navbar/navbar.component';
import { ImageElasticSearchComponent } from './image-elastic-search/image-elastic-search.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { environment } from '../environments/environment'
import {TranslateModule,TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


//imports for google login
import {
  GoogleApiModule, 
  NgGapiClientConfig, 
  NG_GAPI_CONFIG,
 
} from "ng-gapi";
import { ReferencePlateComponent } from './reference-plate/reference-plate.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { AdminConsoleComponent } from './admin-console/admin-console.component';

let gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.client_id ,
  discoveryDocs: [],
  scope: [
    "openid"
  ].join(" ")
};
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '../assets/i18n/', '.json');;
}

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    UploadImageComponent,
    ImageCropperComponent,
    SegmentSliderComponent,
    SearchReportComponent,
    MpRaDropdownComponent,
    ImageResultComponent,
    HomeComponent,
    RegisterComponent,
    ExploreComponent,
    TLCComponent,
    JumboKeywordComponent,
    NavbarComponent,
    ImageElasticSearchComponent,
    GoogleLoginComponent,
    ReferencePlateComponent,
    InstructionsComponent,
    AdminConsoleComponent,
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpModule,
    AngularFileUploaderModule,
    FormsModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DemoMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    routing,
    AngularDraggableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BsDropdownModule.forRoot(),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    NgxGalleryModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    
  ],

  providers: [
    AuthGuard,
        AlertService,
        Title,
        PagerService,
        UsersService
      ],
  bootstrap: [AppComponent]
})
export class AppModule {}

