import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../_services/users.service';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';
import { TargetLocator } from 'selenium-webdriver';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  currentUser=JSON.parse(sessionStorage.getItem('currentUser'));
  timer :number //=parseInt(sessionStorage.getItem('timer'));
  refresh_token = JSON.stringify(sessionStorage.getItem('refresh_token'));
  userName=this.currentUser.name;

  userType=this.currentUser.type;
  new_id_token;
  expires_in;
  greeting=this.userName

    title = '';
    loggedIn=false;
    accessErrorFlag=false
    englishSelected=true;
    frenchSelected=false;

    //Radio button label variable
    labelPosition 

    //Modal for asking user selection of search type
    modalDisplay='none'
    

    //Modal for asking user selection of language
    language='en'
    languageModalDisplay='none'

    //Modal for showing access error
    accessModalDisplay='none'



    Auth_backend="https://backend-dot-solvay-ri-tlc-dev.appspot.com";
    refresh_token_flag=false;
    constructor(
        private router: Router,
        private translate:TranslateService,
        private userservice:UsersService,
        private updateService:UpdateService){
        updateService.loggedIn$.subscribe(
            data=>{
              this.loggedIn=data;
            }
          )
          userservice.expiryTime$.subscribe(
             data=>{
                 this.timer=parseInt(data)-300000;
            }
          )
          this.updateService.currentLanguage$.subscribe(
            data=>{
                this.language=data;
                translate.use(this.language);
            }
        )
        //Code for language selection
        
        this.language=this.translate.currentLang
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang(sessionStorage.getItem("language"));
        const browserLang = translate.getBrowserLang();
        translate.use(this.language);
        }
    logout(){
        
        this.loggedIn=false;
        this.userservice.signOut()
        this.router.navigate(['/login']);
    }

    openAdminConsole(){
        if(this.userType=="admin"){
        window.open('#/admin-console')
       // this.router.navigate(['/admin-console'])
         }
        else{
            this.accessModalDisplay='block'
        }

    }

    closeAccessErrorModal(){
        this.accessModalDisplay='none'
    }
    

    openFAQ(){
        window.open("assets/FAQ document.pdf", "_blank");
    }
    selectEnglish(){
        this.frenchSelected=false;
        this.englishSelected=true;
        sessionStorage.setItem("language","en")
        this.updateService.updateLanguage();
        this.onCloseLanguageHandle()
    }
    selectFrench(){
        this.englishSelected=false;
        this.frenchSelected=true;
        sessionStorage.setItem("language","fr")
        this.updateService.updateLanguage();
        this.onCloseLanguageHandle()
    }
    //function for handling user selection modal
  openModal(){
   this.labelPosition=null
   this.modalDisplay='block';
  }

  onCloseHandled(){   
   this.modalDisplay='none'; 
  }

  onCloseLanguageHandle(){
   this.languageModalDisplay='none'
  }

  //Handling radio button click
  loginAgain(){
    this.labelPosition=="login"
    this.userservice.LoginModalFlag()
    this.userservice.signIn()
    this.modalDisplay='none'
    }

    logoutFromModal(){
        this.userservice.signOut()
        this.modalDisplay='none'
    }
    

    ngOnInit(){
        this.accessErrorFlag=false;
        this.language=sessionStorage.getItem("language");        
        if(sessionStorage.getItem("select language")=='yes'){
            this.languageModalDisplay='block';
            sessionStorage.setItem("select language","no")
        }
        if(sessionStorage.getItem("select language")=='yes'){
            this.languageModalDisplay='block';
            sessionStorage.setItem("select language","no")
        }
     
       setInterval(()=> {
        this.userservice.updateTimer()
       
         if (this.timer && (Date.now() > this.timer) && this.modalDisplay=='none'){
             this.openModal()}
          
        },45000);
    }
    

}
