import { Component, OnInit } from '@angular/core';
import { ElasticsearchService } from '../_services/elasticsearch.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RestService } from '../_services/rest.service';
import {PagerService} from '../_services/pager.service'
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation} from 'ngx-gallery';
import {MatChipInputEvent} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import 'hammerjs';
import {Options,ChangeContext, PointerType} from 'ng5-slider';
import { UpdateService } from '../_services/update.service';

export interface JumboKeyword {
  name: string;
}

export interface RangeSliderModel {
  minValue: number;
  maxValue: number;
  options: Options;
}


@Component({
  selector: 'app-search-report',
  templateUrl: './search-report.component.html',
  styleUrls: ['./search-report.component.css']
})
export class SearchReportComponent implements OnInit {

  /*Code for jumbo keyword tag*/
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  jumboKeyword: JumboKeyword[] = [
  ];  

   /* Image Gallery Code*/
   galleryOptions: NgxGalleryOptions[];
   galleryImages: NgxGalleryImage[];
   modalGalleryOptions: NgxGalleryOptions[];
   showLoading=0;
   SearchErr = 0;
   showNoResult=0;
   language='en';
   displayLoading=false
 
   ngOnInit(): void {
     this.language=sessionStorage.getItem("language");
 
     this.galleryOptions = [
         {
             width: '207px',
             height: '107px',
             thumbnailsRemainingCount:true,
              thumbnailsColumns: 3,
              thumbnailsRows: 2,
              imageAnimation: NgxGalleryAnimation.Slide,
            image:false,
            imageSize:'4%',
            previewCloseOnClick:true,
            previewZoom:true,
            imageDescription:true,
         }
     ];

     this.modalGalleryOptions=[
      {
        width: '600px',
        height: '600px',
        imageAnimation: NgxGalleryAnimation.Slide,
       imageSize:'4%',
       previewCloseOnClick:true,
       previewZoom:true,
       imageDescription:true,
       thumbnails:false,
       imageArrows:false
    }
     ];
     
 }
  
 
 /* Search Results Code */ 
  searchResults:any;
  private queryText = '';
  
  jumboVisible:boolean=false;
  
   searchFlag = 0;
   
 // pager object
 pager: any = {};
 
 // paged items
 pagedItems: any[];
 exactMatch=1;
 showSearchResults=0;

 //Variables to show count of results
 resultCount
 startIndex
 endIndex

 //variable for controlling opening and closing of associated images modal
 modalDisplay='none'
 modalImageList
 imageModalDisplay='none'
 imageModalGallery: NgxGalleryImage[]

 //Variables for year filter
 startYear=1990
 currentDate=new Date()
 endYear=this.currentDate.getFullYear()
 yearModalDisplay='none'
 selectedStartYear=this.startYear
 selectedEndYear=this.endYear
 newKeywordSearchedFlag
 yearFilterReset=0;

 //Variable for slider options
 
  yearSlider: RangeSliderModel={
    minValue: this.startYear,
      maxValue: this.startYear+2,
  
      options: {
        floor: this.startYear,
        ceil: this.endYear,
        step: 1,
        noSwitching: true,
        showTicks: false,
        draggableRange:true,
        pushRange:true,
        minRange: 1,
        maxRange: 2
        
    }
  }

  constructor(
    private es: ElasticsearchService,
    private translate:TranslateService,
    private sanitizer: DomSanitizer,
    private restService: RestService,
    private pagerService: PagerService,
    private updateService:UpdateService)  {
    this.queryText = '';
   
    //Updating jumbo visibility
    this.updateService.jumboVisible$.subscribe(
      data=>{
        this.jumboVisible=data;
      }
    );

    //Updating jumbo results
    this.updateService.jumboResults$.subscribe(
      data=>{
        var k=Object.keys(data);
        if (k[0]!="Response"){
        this.searchResults=data;
        this.updateService.updateLoaderForReportSearch(0);
        this.setPage(1);
        }
        else {
        this.searchResults={}
        }
    }
    );

    this.updateService.selectedJumboKeyword$.subscribe(
      data=>{
        var temp=data;
        if ((temp || '').trim()) {
          this.jumboKeyword.push({name: temp.trim()});
        }

      }
    );

    this.updateService.showSearchLoader$.subscribe(
      data=>{
      this.pager={};
      this.pagedItems=[];
      this.searchFlag=1;
      this.SearchErr=0;
      this.showLoading=data;
      }
    );
    this.updateService.currentLanguage$.subscribe(
      data=>{
          this.language=data;
          translate.use(this.language);
      }
  )
      //Code for language selection
      this.language=this.translate.currentLang
      translate.addLangs(['en', 'fr']);
      this.language=sessionStorage.getItem("language");
      translate.setDefaultLang(sessionStorage.getItem("language"));
      const browserLang = translate.getBrowserLang();
      translate.use(this.language);

      this.updateService.yearFilter$.subscribe(
        data=>{
          this.yearFilterReset=data;
        }
      )
      
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.jumboKeyword.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(jumboKeyword: JumboKeyword): void {
    const index = this.jumboKeyword.indexOf(jumboKeyword);

    if (index >= 0) {
      this.jumboKeyword.splice(index, 1);
    }
  }



  search($event,type) {
    if (type=="i"){
      this.queryText = $event.target.value;
    }
    else {
      this.queryText=$event;
    } 
      var obj={
        "query":this.queryText,
        "yearFlag": 0,
        "startYear": this.startYear,
        "endYear": this.endYear
      };
      //Resetting start year and end year
      this.selectedStartYear=this.startYear
      this.selectedEndYear=this.endYear
      this.showNoResult=0;

      //post request for elastic free text search
      this.es.fullTextSearch(obj).subscribe(
          Response => {
            
            this.showNoResult=0;
            
            this.searchResults=JSON.parse(JSON.stringify(Response.body));
            if (this.searchResults.length==0){
              this.pager={}
              this.pagedItems=[]
              this.showNoResult=1;
            }
            this.updateService.updateLoaderForReportSearch(0);
            if (this.showNoResult==1){
              this.showSearchResults=0;
            }
            else{
              this.showSearchResults=1;
            }
            this.setPage(1)

            },
            error =>{
                this.showLoading = 0;
                this.SearchErr = 1;
                
            });
            
            //Resetting year filter
            this.yearFilterReset=0;

            //Resetting slider values
            this.yearSlider.minValue=this.startYear
            this.yearSlider.maxValue=this.endYear
      
  }

  jumboSearch(type){
    
    var keyWordList:string[]=[];
    for (var i=0;i<this.jumboKeyword.length;i++){
      keyWordList.push(this.jumboKeyword[i].name)
    }
    if(this.yearFilterReset==1){
      this.selectedStartYear=this.startYear
      this.selectedEndYear=this.endYear
      this.yearFilterReset=0
    }
    var obj={
      "query":keyWordList,
      "exactFlag": this.exactMatch,
      "yearFlag": 1,
      "endYear": this.selectedEndYear,
      "startYear": this.selectedStartYear
    };
    this.showNoResult=0;
    this.es.jumboTextSearch(obj).subscribe(
      Response => {
        this.showNoResult=0;
        
        this.searchResults=JSON.parse(JSON.stringify(Response.body));

        if (this.searchResults.Response=="Sorry. No results found!"){
          this.pager={}
          this.pagedItems=[]
          this.showNoResult=1;
        }
        this.updateService.updateLoaderForReportSearch(0);
        if (this.showNoResult==1){
          this.showSearchResults=0;
        }
        else{
          this.showSearchResults=1;
        }

        this.setPage(1)

      },
      error =>{
        this.showLoading = 0;
        this.SearchErr = 1;
    }
      );
         //Resetting slider values
         this.yearSlider.minValue=this.selectedStartYear
         this.yearSlider.maxValue=this.selectedEndYear


  }

  //function for exact match handling
  onExactMatch(){
    if (this.exactMatch==1){
      this.exactMatch=0;
    }
    else{
      this.exactMatch=1;
    }
    this.updateService.updateJumboMatchtype(this.exactMatch);
    
    }

  
    //pagenation function

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.searchResults.length, page,10);
    // get current page of items
    this.pagedItems = this.searchResults.slice(this.pager.startIndex, this.pager.endIndex + 1);
    //Variable to show result number near pagination bar
    this.resultCount=this.searchResults.length
    this.startIndex=this.pager.startIndex + 1
    this.endIndex=this.pager.endIndex + 1
    window.scroll(0,0);
  }

  //function to open associated image modal
  openImagemodal(associatedImages){
    // this.modalImageList=associatedImages
    // this.modalDisplay='block'
    this.displayLoading=true
    var request_payload = {
      "original_path_object_list": associatedImages
    }
    console.log("Show Associated Images");
    console.log(associatedImages);
    
    this.restService.getAssociatedImages(request_payload).subscribe(Response =>{
      
     var temp=JSON.parse(JSON.stringify(Response.body));
     // this.tempImageResponse=temp
     // this.temp_ref_fold_list=temp.ref_plate_folder_list
     // this.temp_req_id=temp.request_id_list
     console.log("printing associated image response");
     console.log(temp);
     this.modalImageList=temp.signed_url_list
     this.modalDisplay='block'
     this.displayLoading=false
    //  this.openImage(temp.signed_url_list)
   },
   error =>{
       var matchImageErr = true;
       this.updateService.updateErrflags(matchImageErr);
   });
 
  }
  //function to close associated image modal
  onCloseHandled(){
    this.modalDisplay='none'
  }
  
  //function to open specific image
  openImage(imageList, image){
    this.imageModalDisplay='block'
    // this.imageModalGallery=imageList
    this.imageModalGallery=[image]
  }

  //function to close specific image
  onCloseImageHandled(){
    this.imageModalDisplay='none'
  }

  //function to open year model
  openYearModal(){    
    this.yearModalDisplay='block'
    //Setting slider values
    this.yearSlider.minValue=this.selectedStartYear
    this.yearSlider.maxValue=this.selectedEndYear
  }

  //function to close year model
  onCloseYearModal(){
    this.yearModalDisplay='none'
    console.log("Modal Closed")
  }

  //function to handle change in year slider
  onUserChangeYear(event:ChangeContext){
    this.selectedStartYear=event.value
    this.selectedEndYear=event.highValue
  }

  //function to apply year filter
  applyYearFilter(){
    if (this.jumboVisible){
      this.yearFilterSearch("jumbo")
    }else{
      this.yearFilterSearch("freeText")
    }
    this.yearModalDisplay='none'
  }

//performing year filter based search
  yearFilterSearch(type){
    if (type=="jumbo"){
      var keyWordList:string[]=[];
    for (var i=0;i<this.jumboKeyword.length;i++){
      keyWordList.push(this.jumboKeyword[i].name)
    }
    var obj={
      "query":keyWordList,
      "endYear": this.selectedEndYear,
      "exactFlag": this.exactMatch,
      "startYear": this.selectedStartYear,
      "yearFlag": 1
    };
    this.es.jumboTextSearch(obj).subscribe(
      Response => {
        this.showNoResult=0;
        
        this.searchResults=JSON.parse(JSON.stringify(Response.body));

        if (this.searchResults.Response=="Sorry. No results found!"){
          this.pager={}
          this.pagedItems=[]
          this.showNoResult=1;
        }
        this.updateService.updateLoaderForReportSearch(0);
        if (this.showNoResult==1){
          this.showSearchResults=0;
        }
        else{
          this.showSearchResults=1;
        }

        this.setPage(1)

      },
      error =>{
        this.showLoading = 0;
        this.SearchErr = 1;
    });
    }else{
      var objFree={
        "query":this.queryText,
        "yearFlag": 1,
        "startYear":this.selectedStartYear,
        "endYear": this.selectedEndYear
      };
      this.es.fullTextSearch(objFree).subscribe(
        Response => {
            
          this.showNoResult=0;
          
          this.searchResults=JSON.parse(JSON.stringify(Response.body));
          if (this.searchResults.length==0){
            this.pager={}
            this.pagedItems=[]
            this.showNoResult=1;
          }
          this.updateService.updateLoaderForReportSearch(0);
          if (this.showNoResult==1){
            this.showSearchResults=0;
          }
          else{
            this.showSearchResults=1;
          }
          this.setPage(1)

          },
          error =>{
              this.showLoading = 0;
              this.SearchErr = 1;
          }
      )
    }
  }
}
